





































































































import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import DatePicker from '@/components/date-picker.vue'
import LsPagination from '@/components/ls-pagination.vue'
import {
    apiCancelpartTj,
    apiCancelpartTjCount
} from '@/api/order/order'
@Component({
    components: {
        LsDialog,
        LsPagination,
        DatePicker
    }
})
export default class Category extends Vue {
    PrintDialogVisible = false // 打印配送单弹窗is show
    // 查询条件
    queryObj: any = {
        start_time: '', //否	string	申请开始时间 格式：年月日 时：分：秒
        end_time: '', //否	string	申请结束时间 格式：年月日 时：分：秒
    }
    // 统计数量数据
    countData:any = {
        order_amount: 0,
        order_count: 0,
        refund_amount: 0
    }
    

    pager = new RequestPaging()
    apiCancelpartTj = apiCancelpartTj
    getList(page?: number): void {
        console.log(this.queryObj)

        page && (this.pager.page = page)
        this.pager.request({
            callback: apiCancelpartTj,
            params: {
                ...this.queryObj
            }
        })
    }

    // 重置
    handleReset() {
        this.queryObj = {
            start_time: '', //否	string	申请开始时间 格式：年月日 时：分：秒
            end_time: '', //否	string	申请结束时间 格式：年月日 时：分：秒
        }
        this.getList()
    }

    created() {
        apiCancelpartTjCount({}).then(res => {
            this.countData = res
        })
        this.getList()
    }
}
